var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.valueLine.n_type != '4' && _vm.valueLine.n_type != '5')?_c('div',[_vm._l((_vm.project),function(multiTextItem,increment){return _c('v-row',{key:increment,staticClass:"imsicontainer",attrs:{"no-gutters":""}},[_c('v-col',[_c('associatedOption',{attrs:{"fieldAttributes":{
          name: 'Project',
          associatedOption: _vm.result.additional.projectsArray,
        },"type":"combobox","fieldAttrInput":{
          ..._vm.fieldAttrInput,
          id: 'projectSelector_' + increment,
        },"templateContent":_vm.result},model:{value:(_vm.project[increment]),callback:function ($$v) {_vm.$set(_vm.project, increment, $$v)},expression:"project[increment]"}})],1),_c('v-col',{staticClass:"mt-2",staticStyle:{"max-width":"30px"}},[_c('v-icon',{attrs:{"id":'projectSector_' + increment},on:{"click":function($event){return _vm.addRow(increment)}}},[_vm._v("mdi-plus")])],1),_c('v-col',{staticClass:"mt-2",staticStyle:{"max-width":"30px"}},[_c('trash',{attrs:{"result":_vm.result},on:{"click":function($event){return _vm.removeRow(increment)}}})],1)],1)}),_c('input',{attrs:{"type":"hidden","name":_vm.field},domProps:{"value":JSON.stringify(_vm.project)}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }